.App {
  margin-top: 0px;
  width: 100%;
  background-color: #d3d3d3;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App .navbar-brand {
  font-weight: bold;
  padding-left: 40px;
  margin-right: 120px;
  margin-top: 25px;
  width: 100%;
  color: whitesmoke;
}

.App .NavBar {
  background-color: #3f51b5;
  height: 100px;
}

.App .collapse {
  color: whitesmoke;
  margin-top: 25px;
}
