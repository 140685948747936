.watchSlip {
  background-color: whitesmoke;
  width: 350px;
  margin: 67px 3vw;
  height: auto;
  position: sticky;
  position: -webkit-sticky;
  top: 20px;
  max-height: 75vh;
  overflow: hidden;

  > header {
    min-width: 350px;
  }

  .swipeable-views {
    min-width: 350px;
  }

  &:hover {
    overflow-y: auto;
  }
  .title-summary {
    font-weight: bold;
    display: flex;
    justify-content: center;
  }

  .summary-content {
    display: flex;
  }
}

.watchSlip .tabs {
  background-color: whitesmoke;
}

.watchSlip .submit {
  width: 100%;
  height: 50px;
  font-size: 16px;
}

.watchSlip .tabText {
  text-transform: capitalize;
  font-size: 13px;
}

@media screen and (max-width: 1000px) {
  .watchSlip {
    position: fixed;
    width: 100%;
    left: 0;
    margin: 0;
    top: 60px;
    bottom: 50px;
    z-index: 2;
    animation: slideInBody;
    animation-duration: 1s;
    overflow-y: auto;
    max-height: none;

    .swipeable-views {
      position: relative;
      top: 60px;
    }
  }

  .watchSlip .closeButton {
    float: right;
    width: 20px;
    font-size: 16px;
    color: black;
  }

  .watchSlip .tabs {
    position: fixed;
    top: 60px;
    width: 100%;
    box-shadow: 2px 2px 3px rgb(181, 181, 181);
    animation: slideInTabs;
    animation-duration: 1s;
  }

  .watchSlip .submit {
    position: fixed;
    bottom: 80px;
  }
}

// @keyframes slideInBody {
//   0% {
//     top: 90vh;
//   }
//   100% {
//     top: 100px;
//   }
// }

// @keyframes slideInTabs {
//   0% {
//     top: 85vh;
//   }
//   100% {
//     top: 60px;
//   }
// }

// @keyframes slideOutBody {
//   0% {
//     top: 100px;
//   }
//   100% {
//     top: 90vh;
//   }
// }

// @keyframes slideOutTabs {
//   0% {
//     top: 60px;
//   }
//   100% {
//     top: 85vh;
//   }
// }
