.settings-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 63vh;
  margin-top: 20px;
  margin-bottom: 50px;
}

.settings-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
}

.settings-item {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 2px solid rgb(194, 194, 194);
}

.settings-item#final {
  border: none;
}

.settings-item-header {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-item-header .header-right {
  transform: scale(1);
}

.settings-item-header:focus .header-right {
  transform: rotate(90deg) scale(2);
}

.placeholder {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  /* border: 1px solid white; */
  margin: 20px;
}

.settings-item:hover {
  cursor: pointer;
}

.settings-item .icon {
  vertical-align: middle;
  height: 32px;
  width: 32px;
  margin-right: 20px;
  color: #337ab7;
}

.settings-item #profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  padding-bottom: 0px;
}

.settings-item #profile > * {
  background: whitesmoke;
  width: 80%;
  margin: 10px 0px;
}

.settings-item .radio-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  padding: 20px;
  padding-bottom: 0px;
}

.settings-item .radio-buttons > * {
  margin: 10px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.settings-item .radio-buttons input {
  margin: 0px;
}

.settings-item .radio-buttons h4 {
  margin: 0px 25px;
}

.settings-page .submit-button {
  margin: 20px;
  padding: 10px 20px;
  width: 250px;
  color: white;
  border-radius: 10px;
  // background: rgb(233, 70, 97);
}

// .settings-page .submit-button:hover {
//   background: rgb(233, 70, 97);
// }

@media screen and (max-width: 1000px) {
  .settings-page {
    overflow-x: hidden;
  }
}
