.root-history-content {
  max-width: 800px;
  min-width: 500px;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.root-history-content .table {
  width: 100%;
  background: whitesmoke;
}

.root-history-content .alert-history-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}

.root-history-content .info-icon {
  height: 20px;
  width: 20px;
}

.root-history-content .columns {
  background-color: lightblue;
}

.root-history-content .table .cell {
  font-size: 14px;
}

.root-history-content .table .hit-text {
  color: darkgreen;
  font-size: 14px;
  font-weight: bold;
}

.root-history-content .table .miss-text {
  color: red;
  font-size: 14px;
  font-weight: bold;
}

.root-history-content .miss {
  background: #ffcccb;
}

.root-history-content .table .live-text {
  color: black;
  font-size: 14px;
  font-weight: bold;
}

.root-history-content .live {
  background: lightyellow;
}

.root-history-content .table .push-text {
  color: black;
  font-size: 14px;
  font-weight: bold;
}

.root-history-content .table .not_started-text {
  color: black;
  font-size: 14px;
  font-weight: bold;
}

.root-history-content .not_started {
  background: lightyellow;
}

.root-history-content .hit {
  background: rgb(184, 244, 184);
}

@media screen and (max-width: 800px) {
  .root-history-content {
    width: 100vw;
    min-width: 0px;
  }

  .root-history-content .table {
    width: 95%;
    background: whitesmoke;
  }

  .root-history-content .table .cell {
    font-size: 12px;
  }

  .root {
    display: flex;
    justify-content: center;
  }
}
