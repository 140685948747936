#pricing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 63vh;
}

#pricing .hero-content {
  padding: 40px;
}

#pricing .card-header {
  background: lightblue;
}

#pricing ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#pricing .buy-button {
  display: flex;
  justify-content: center;
}

#pricing .grid {
  margin-bottom: 32px;
}

#pricing .buy-button2 {
  width: 200px;
}

#pricing .card-pricing {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 10px;
}

#pricing .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1000px) {
  margin-bottom: 100px;
}
