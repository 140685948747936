#about {
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 500px;
  color: white;
  background-image: linear-gradient(#001f3f, #1c64ac);
  width: 100%;
}

#top-section .logo {
  font-size: 60px;
  font-weight: 500;
}

#top-section .logo i {
  font-size: 60px;
  font-weight: 300;
}

#top-section h3 {
  text-align: center;
  margin: 20px;
  line-height: 50px;
}

html {
  scroll-behavior: smooth;
}

#how-it-works {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  width: 100vw;
  margin-bottom: 100px;
}

#how-it-works > h2 {
  margin: 50px;
}

#how-it-works .hiw-section {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 90%;
}

#how-it-works .header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

#how-it-works .header h3 {
  font-weight: 700;
}

#how-it-works .circle {
  background: #001f3f;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 30px;
  font-weight: 700;
  margin-right: 40px;
}

#how-it-works .line {
  width: 2px;
  height: 250px;
  background: lightgrey;
  position: relative;
  top: 0px;
  left: -20px;
}

#how-it-works .content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
}

#how-it-works .content p {
  width: 350px;
  font-size: 20px;
  line-height: 30px;
}

#how-it-works .content img {
  width: 400px;
}

#faq {
  max-width: 900px;
  width: 100vw;
  margin-bottom: 60px;
}

#faq h2 {
  text-align: center;
  margin: 50px;
}

#faq .questionText {
  font-size: 22px;
  padding: 10px;
  font-weight: 700;
}

#faq .answersText {
  font-size: 22px;
  padding: 10px;
  font-weight: 300;
}

@media screen and (max-width: 1000px) {
  #top-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 400px;
    margin-bottom: 0px;
    overflow-x: hidden;
  }

  #top-section h3 {
    text-align: center;
    margin: 20px;
    font-size: 18px;
    line-height: 30px;
  }

  #top-section .button-cont {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 130px;
  }

  #how-it-works .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
  }

  #how-it-works .circle {
    background: #001f3f;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
    font-weight: 700;
    margin-right: 20px;
  }

  #how-it-works .content {
    flex-direction: column-reverse;
    width: 100vw;
  }

  #how-it-works .content p {
    max-width: 350px;
    width: 90%;
    font-size: 18px;
    line-height: 24px;
  }

  #how-it-works .content img {
    max-width: 350px;
    width: 90%;
    margin-bottom: 20px;
  }

  #how-it-works .line {
    display: none;
  }

  #faq {
    margin-bottom: 150px;
  }

  #faq h2 {
    font-size: 32px;
  }

  #faq .questionText {
    font-size: 18px;
    padding: 0px;
    font-weight: 700;
  }

  #faq .answersText {
    font-size: 18px;
    padding: 0px;
    font-weight: 300;
  }
}
