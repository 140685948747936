.TopBar {
  height: 60px;
}

.TopBar .Button {
  text-align: center;
}

.TopBar .tabs {
  padding-bottom: 0px;
}

.TopBar .logo {
  float: left;
  // padding-left: 20px;
  padding-top: 12px;
}

.TopBar .logo:hover {
  background-color: white;
}

.TopBar .tabText {
  font-size: 16px;
  text-decoration: none;
  outline: none;
  text-transform: capitalize;
}

.TopBar .link {
  color: red;
  font-weight: bold;
  text-decoration: none;
  outline: none;
}

@media screen and (max-width: 1000px) {
  #top-bar {
    position: sticky;
    top: 0px;
    width: 100vw;
    z-index: 2;
  }
}
