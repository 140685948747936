#form-dialog-title > h2 {
  font-size: larger;
  text-align: center;
  font-weight: bold;
}

.dialog-field > label {
  font-size: large;
}

.dialog-field input {
  height: 3rem;
  font-size: medium;
  padding: 1rem;
  background-color: rgba(221, 221, 221, 50%);
}

.select-dialog-field {
  width: 550px;
  padding: 0px 0px 10px;
}

.icon-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 10px;
}

.icon-input-container > svg {
  width: 3.25rem;
  height: 3.25rem;
  padding-right: 1rem;
}

.login-button {
  font-size: 1rem;
}

.xButton {
  float: right;
  font-size: 14px;
}
