#content-supported {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

#content-supported .content-row {
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
  font-size: 18px;
}

#content-supported .leagues {
  display: flex;
  flex-direction: row;
}

#content-supported .content-navigation {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: center;
  li {
    color: black;
  }

  li:after {
    content: "•";
    padding: 1.5rem;
  }

  li:last-child:after {
    content: "";
  }
}
