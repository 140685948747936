#dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #001f3f;
  color: whitesmoke;
  width: 80%;
  max-width: 800px;
  padding: 25px;
  border-radius: 14px;
}

#dashboard h1,
#dashboard h2,
#dashboard h3,
#dashboard h4,
#dashboard h5,
#dashboard h6 {
  margin: 0px;
  padding: 0px;
}

#dashboard > .line {
  width: 100%;
  height: 3px;
  background: #062b50;
  margin: 20px 0px;
}

#dashboard .top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

#dashboard .current-month-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#dashboard .current-month-overview .line {
  width: 100%;
  height: 2px;
  background: #062b50;
  margin: 10px 0px;
  justify-content: space-evenly;
}

#dashboard .month-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
}

#dashboard .month-stats > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  height: 50px;
}

#dashboard .date-joined {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#dashboard .middle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 130px;
}

#dashboard .all-time-record {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 130px;
}

#dashboard .top .name-and-edit {
  display: flex;
  align-items: center;
  flex-direction: row;
}

#dashboard .top .plan-and-date {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

#dashboard .top .name-and-tag {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

#dashboard .text {
  max-width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

#dashboard .all-time-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#dashboard .info-icon {
  height: 20px;
  width: 20px;
}

#dashboard .user-plan {
  color: red;
  margin-top: 5px;
  margin-bottom: 10px;
  background: white;
  border-radius: 14px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#dashboard .edit-icon {
  height: 24px;
  width: 24px;
  align-items: center;
  background: #062b50;
  margin: 10px;
  display: flex;
}

#dashboard .stats-cont {
  display: flex;
  background: #062b50;
  border-radius: 14px;
  padding: 10px;
}

#dashboard .stats-cont > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  height: 50px;
}

#dashboard .stats-cont .stats-cont-blur-text {
  color: transparent;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1000px) {
  #dashboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #001f3f;
    color: whitesmoke;
    width: 95%;
    padding: 15px;
    border-radius: 14px;
    overflow-x: hidden;
  }

  #dashboard .month-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  #dashboard .text {
    max-width: 40vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
