.Questions {
  background-color: #3f51b5;
  height: 125px;
}

.Questions .text {
  color: whitesmoke;
  font-size: 48px;
  text-align: center;
  margin-top: 20px;
}

.panel {
  height: 50px;
}
