.xButton {
  float: right;
  top: -15px;
}

.betPicked {
  text-transform: capitalize;
  font-weight: bold;
}

.betType {
  text-transform: capitalize;
}

.line {
  width: 100%;
  height: 1.5px;
  background: lightcoral;
  opacity: 0.5;
}

.button-group {
  display: flex;
  flex-direction: column;
}

.button-group > button {
  height: 30px;
  border-radius: 3px;
  text-align: center;
  width: 25px;
}

.button-group .plus-button-clicked {
  background: green;
  color: white;
  border: 1px solid grey;
  font-size: 20px;
}

.button-group .plus-button-clicked:hover {
  background: green;
  color: white;
  border: 1px solid black;
}

.button-group .minus-button-clicked {
  background: red;
  color: white;
  border: 1px solid grey;
  font-size: 20px;
}

.button-group .minus-button-clicked:hover {
  background: red;
  color: white;
  border: 1px solid black;
}

.button-group .button-not-clicked {
  border: 1px solid grey;
  font-size: 20px;
}

.daily-limit-text {
  font-size: 12px;
  width: 100px;
}

.upgrade-link {
  color: red;
  font-weight: bold;
}

.upgrade-link:hover {
  color: red;
  font-weight: bold;
}

.button-group .button-not-clicked:hover {
  border: 1px solid black;
}

.watch-bet-input-plus input {
  font-size: 24px;
  width: 80px;
  height: 24px;
  color: green;
}

.watch-bet-input-minus input {
  font-size: 24px;
  width: 80px;
  height: 24px;
  color: red;
}

.watch-bet-input-plus label {
  font-size: 11px;
}

.watch-bet-input-minus label {
  font-size: 11px;
}

.button-price-group {
  display: flex;
  justify-content: space-between;
}

.form-control-input .select-control-input-clicked {
  font-size: 24px;
  color: green;
}

.form-control-input .select-control-input-minus {
  font-size: 24px;
  color: red;
}

.form-control-input label {
  font-size: 11px;
}

.buttonsAndSlip {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.emptyWatchSlip {
  h6 {
    font-weight: bold;
    color: grey;
    font-size: 12px;
  }
  img {
    width: 150px;
    height: 150px;
  }
  text-align: center;
}

.watchSlipLoading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .xButton {
    float: right;
    top: -10px;
  }
}
