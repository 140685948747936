.user-dropdown-container {
  position: relative;
}

.profile-dropdown {
  right: 2.75rem;
  top: 6rem;
  position: absolute;
  z-index: 110;
  background-color: #eeeeee;
  animation: 0.4s ease-out fadeIn 1;
  cursor: pointer;
}

.profile-menu-list {
  padding-inline-start: 0;
  margin-bottom: 0;
}

.profile-menu-item {
  list-style: none;
  padding-inline-start: initial;
  font-size: smaller;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  color: #337ab7;
}

.profile-menu-item:hover {
  background-color: lightblue;
  cursor: pointer;
}

.user {
  display: flex;
  align-items: center;
  margin-right: 50px;
  float: right;
  padding-top: 8px;
  cursor: pointer;
}

.user .avatar {
  color: white;
  background: lightblue;
}

.menu-icon {
  height: 40px !important;
  width: 40px !important;
  margin-left: 5px;
}

@media screen and (max-width: 1000px) {
  .user {
    margin-right: 20px;
  }
}
