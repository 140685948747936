@media screen and (max-width: 1000px) {
  #mobile-nav-bar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    height: 80px;
    z-index: 2;
    background: #001f3f;
    display: flex;
    justify-content: space-between;
    padding: 25px;
    align-items: center;
    text-align: center;
    a {
      text-decoration: none;
    }
  }

  .nav-icon-cont {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-icon-cont:hover {
    text-decoration: none;
  }

  .nav-icon-cont > h4 {
    font-size: 12px;
    color: white;
    margin: 0px;
    margin-bottom: 25px;
  }

  .nav-icon-clicked {
    height: 30px !important;
    width: 30px !important;
    color: #99badd;
  }

  .nav-icon-not-clicked {
    height: 30px !important;
    width: 30px !important;
    color: whitesmoke;
    opacity: 0.9;
  }
}
