.icon-input-container-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 10px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.select-dialog-field-2 {
  padding: 0px 0px 10px;
  background: whitesmoke;
  label {
    top: -7px;
  }
}

.feedbackText {
  background: whitesmoke;
  label {
    top: -7px;
  }
}
