#sports-page-cont {
  flex: 1;
}

#sports-page {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  padding: 20px;
  margin-top: 80px;
  min-height: 63vh;
}

#single-odds-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 3vw;
  font-size: 14px;
}

.single-odds {
  height: 100px !important;
  width: 499px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin: 10px 0px;
  background: whitesmoke;
  box-shadow: 1px 1px 5px rgb(174, 174, 174);
}

.more-bets-2 {
  height: 40px !important;
  width: 95vw;
  width: 499px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  background: whitesmoke;
  border: 1px solid rgb(206, 206, 206);
  // border-top: none;
}

.odds-score {
  height: 50%;
  width: 25px;
  margin-right: 10px;
  background: #001f3f;
  display: flex;
  color: white;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.team-name-and-score {
  height: 100%;
  width: 300px;
  display: flex;
  align-items: center;
  border: 1px solid rgb(206, 206, 206);

  h3 {
    height: 100%;
    width: 100%;
    font-size: 14px;
    padding: 0px;
    margin: 0px 0px;
    text-align: center;
    line-height: 47px;
    border-right: none;
    text-transform: capitalize;
  }
}

.odds-status {
  background: red;
  color: white;
  display: flex;
  text-transform: uppercase;
  margin-right: 10px;
  align-items: center;
  height: 50%;
  padding: 0.2rem;
  border-radius: 2px;
  h4 {
    font-size: 12px;
  }
}

.odds-time {
  margin-left: 5px;
}

.odds-time-live {
  height: 100%;
  width: 200px;
  background: transparent;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  align-items: center;
  font-size: 12px;
  border-right: none;
  display: flex;
  flex-direction: row;
}

.more-bets-live {
  height: 100%;
  width: 100px;
  background: #ffff80;
  border: 1px solid rgb(206, 206, 206);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
}

.teams-cont {
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
}

.teams-cont > h3 {
  height: 100%;
  width: 100%;
  font-size: 14px;
  padding: 0px;
  margin: 0px 0px;
  text-align: center;
  line-height: 47px;
  border: 1px solid rgb(206, 206, 206);
  text-transform: capitalize;
}

.odds-buttons-cont {
  position: relative;
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .header {
    position: absolute;
    width: 100%;
    top: -25px;
    left: 0;
    display: flex;
    justify-content: space-evenly;

    h6 {
      width: 33%;
      text-align: center;
      color: grey;
      font-weight: bold;
    }
  }
}

.team-odds {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}

.team-odds > button {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 1px solid rgb(206, 206, 206);
}

.team-odds > button:hover {
  background: #99badd;
  border: 1px solid white;
  color: white;
}

#single-odds-cont .toggle-button {
  margin: 0.5rem;
  border-radius: 10px;
  border: 1px solid grey;
  height: 5vh;
  width: 12vh;
  margin-bottom: 25px;
}

#single-odds-cont .toggle-books {
  margin: 0.5rem;
}

#single-odds-cont .image {
}

#single-odds-cont .toggle-button-books-group {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  max-width: 30rem;
}

.team-odds .buttonOddsNotClicked:hover {
  background: lightgrey;
}

.team-odds .buttonOddsClicked {
  background: #99badd;
  color: white;
}

.nextEvents {
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-transform: capitalize;

  &.books {
    min-height: 75px;
  }
}

.oddsLoading {
  width: 500px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  #sports-page {
    overflow-x: hidden;
  }
  #show-slip {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    height: 50px;
    background: rgb(63, 116, 172);
    color: white;
  }

  .team-odds .buttonOddsNotClicked:hover {
    background: whitesmoke;
    border: 1px solid rgb(206, 206, 206);
    color: black;
  }

  #hide-slip {
    position: fixed;
    bottom: 0px;
    width: 100vw;
    height: 50px;
    left: 0px;
    background: rgb(63, 116, 172);
    color: white;
  }

  .single-odds {
    height: 100px !important;
    width: 95vw;
    max-width: 600px;
    flex-wrap: wrap;
  }

  .more-bets-2 {
    height: 30px !important;
    width: 95vw;
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    background: white;
    border: 1px solid rgb(206, 206, 206);
  }

  .more-bets-live {
    width: 50%;
    height: 50px;
    background: #ffff80;
    border-left: none;
    font-weight: bold;
  }

  .teams-cont {
    height: 100px;
    width: 50%;
  }

  .odds-buttons-cont {
    height: 100px;
    width: 50%;
  }

  .odds-buttons-cont button {
    padding: 0px;
  }

  .oddsLoading {
    position: fixed;
    top: 60vh;
    left: 50vw;
    transform: translateX(-50%) translateY(-50%);
    height: auto;
  }

  .hack {
    margin-bottom: 75px;
  }

  .team-name-and-score {
    height: 100%;
    width: 100%;
    h3 {
      font-size: 1.2rem;
    }
  }
}
