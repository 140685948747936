#sports-bar {
  background: #001f3f;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  top: 60px;
  width: 100vw;
}

#sports-bar h4 {
  font-size: 16px;
  margin: 3px;
}

#extra-sports-bar {
  background: #001f3f;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  top: 84px;
  width: 100vw;
}

#extra-sports-bar h4 {
  font-size: 16px;
  margin: 3px;
}

.sports-icon-clicked-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
  color: white;
  border-radius: 10px;
  text-decoration: none;
  background: #99badd60;
}

.sports-icon-clicked-cont:hover {
  text-decoration: none;
  background: #99badd60;
  color: white;
}

.sports-icon-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
  color: white;
  border-radius: 10px;
}

.sports-icon-cont:hover {
  text-decoration: none;
  background: #99badd60;
  color: white;
}

.sports-icon-cont-disabled {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
  color: white;
  border-radius: 10px;
  color: #999;
  pointer-events: none;
}

.sports-icon {
  height: 40px !important;
  width: 40px !important;
}

@media screen and (max-width: 1000px) {
  #sports-bar {
    top: 60px;
    position: fixed;
    width: 100vw;
    z-index: 1;
  }

  #extra-sports-bar {
    top: 121px;
    position: fixed;
    width: 100vw;
    z-index: 1;
  }

  .sports-icon-cont {
    padding: 10px 0px;
  }

  .sports-icon-cont > h4 {
    display: none;
  }

  .sports-icon-clicked-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    color: white;
    border-radius: 10px;
    text-decoration: none;
    background: #99badd60;
  }

  .sports-icon-clicked-cont:hover {
    text-decoration: none;
    background: #99badd60;
    color: white;
  }

  .sports-icon-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    color: white;
    border-radius: 10px;
  }

  .sports-icon-cont-disabled {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    color: #999;
    pointer-events: none;
    border-radius: 10px;
    text-decoration: none;

    h4 {
      display: none;
    }
  }

  .sports-icon-cont:hover {
    text-decoration: none;
    background: #99badd60;
    color: white;
    h4 {
      display: flex;
    }
  }
}
