.betPicked {
  text-transform: capitalize;
  font-weight: bold;
}

.betType {
  text-transform: capitalize;
}

.emptyWatchSlip {
  h6 {
    font-weight: bold;
    color: grey;
    font-size: 12px;
  }
  img {
    width: 150px;
    height: 150px;
  }
  text-align: center;
}

.activeWatchSlipLoading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .pagination {
    margin-bottom: 50px;
  }
}
