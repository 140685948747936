.footer {
  padding: 1rem;
  height: 100%;
  background-color: #001f3f;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footer-row {
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
}

// .row-inner {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }

.footer-social {
  display: flex;
  flex-direction: column;
}

.footer-social-list {
  display: flex;
  flex-direction: row;
  padding-left: 1rem;

  list-style: none;
}

.footer-social-list > li {
  cursor: pointer;
  margin-right: 1rem;
}

.footer-email {
  font-size: smaller;
}

.footer-row.copyright {
  padding-top: 4rem;
}
.copyright {
  color: lightgrey;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  padding-right: 1.5rem;
}

.footer-navigation {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: center;
  a {
    color: white;
    text-decoration: none;
  }
  li {
    color: #99badd;
  }
}

.footer-navigation > li:after {
  content: "•";
  padding: 1.5rem;
}

.footer-navigation > li:last-child:after {
  content: "";
}
