.entry-statistics-root {
  max-width: 800px;
  min-width: 500px;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.entry-statistics-root .table .columns {
  font-weight: bold;
  background-color: lightblue;
}

.entry-statistics-root .alert-statistics-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}

.entry-statistics-root .info-icon {
  height: 20px;
  width: 20px;
}

.entry-statistics-root .table {
  width: 100%;
  background: whitesmoke;
}

.entry-statistics-root .table .cell {
  font-size: 14px;
}

@media screen and (max-width: 800px) {
  .entry-statistics-root {
    width: 100vw;
    min-width: 0px;
  }

  .entry-statistics-root .table {
    width: 95%;
    background: whitesmoke;
  }

  .entry-statistics-root .table .cell {
    font-size: 12px;
  }

  .root {
    display: flex;
    justify-content: center;
  }
}
