.user-profile input {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3rem;
  font-size: medium;
  padding: 1rem;
  background-color: whitesmoke;
}

.icon-input-container-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 10px;
  margin-top: 20px;
}

.user-profile label {
  top: -7px;
}
